import type { ComponentProps } from 'react'

export const HapstackIcon = ({
  className,
  ...props
}: ComponentProps<'svg'>) => {
  return (
    <svg
      viewBox="0 0 512 483"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_74_9)">
        <g clipPath="url(#clip1_74_9)">
          <path
            opacity="0.5"
            d="M512 359.522C510.784 428.583 400.096 483 262.885 483C259.85 483 256.827 482.953 253.744 482.894C112.365 480.426 -1.25178 421.214 -8.8485e-06 350.582C0.153511 343.202 1.51157 335.986 4.09779 329.007C18.3397 344.867 37.7422 359.321 61.6913 371.851C113.026 398.623 181.071 414.034 253.319 415.297C256.508 415.345 259.684 415.404 262.873 415.404C287.684 415.404 311.527 413.739 334.106 410.656C336.787 416.419 340.471 421.769 345.065 426.363C346.022 427.272 346.836 428.075 347.746 428.89L347.852 428.996L347.958 429.102C358.209 438.042 371.293 442.943 384.791 442.943C387.165 442.943 389.645 442.789 392.018 442.494C414.043 439.66 432.229 423.706 438.452 401.87L439.61 397.831V397.076C440.318 393.486 440.672 389.754 440.672 386.01V381.31C468.459 368.733 491.346 353.015 507.76 335.136C510.689 343.072 512.154 351.197 512 359.534V359.522Z"
            fill="url(#paint0_linear_74_9)"
          />
          <path
            opacity="0.78"
            d="M215.683 354.125C215.683 362.711 217.549 370.953 220.938 378.322C127.622 370.741 50.4844 338.254 17.3831 295.421C14.7025 291.937 12.2816 288.347 10.2032 284.71C7.78226 280.565 5.76289 276.326 4.19226 271.98C1.32263 264.091 -0.141715 255.907 -4.20306e-06 247.617C0.106279 240.496 1.4171 233.517 3.83799 226.75C11.018 234.84 19.6033 242.563 29.4049 249.837C32.9949 252.518 36.7857 255.14 40.7181 257.714C44.4617 260.088 48.2997 262.461 52.3384 264.741C55.3734 266.512 58.5028 268.224 61.6795 269.89C104.015 291.973 157.617 306.368 215.672 311.364V354.102L215.683 354.125Z"
            fill="url(#paint1_linear_74_9)"
          />
          <path
            opacity="0.78"
            d="M512 256.569C511.846 263.949 510.488 271.224 507.902 278.191C506.391 282.49 504.418 286.682 502.092 290.768C499.919 294.453 497.392 298.09 494.617 301.68C482.241 317.339 463.795 331.392 440.661 343.107V279.349C447.179 276.42 453.438 273.291 459.402 270.008C463.594 267.728 467.586 265.355 471.471 262.887C475.309 260.466 479.052 257.986 482.583 255.364C492.231 248.397 500.769 240.91 508.044 232.938C510.772 240.614 512.142 248.55 511.988 256.58L512 256.569Z"
            fill="url(#paint2_linear_74_9)"
          />
          <path
            d="M504.395 164.562V164.61C504.289 170.833 503.179 177.057 500.958 183.174C499.648 187.024 498.018 190.708 495.845 194.558C493.873 197.995 491.594 201.384 489.019 204.679C480.871 215.154 469.593 225.014 455.635 234.025C451.183 236.859 447.238 239.185 443.436 241.264C439.291 243.496 434.886 245.716 429.772 248.042C425.072 250.322 420.113 252.388 415.2 254.266L410.346 256.144V386.01C410.346 388.644 409.992 391.171 409.284 393.545V393.592C406.449 403.512 398.301 411.093 388.192 412.416C387.023 412.569 385.913 412.617 384.803 412.617C378.532 412.617 372.663 410.385 367.904 406.24C367.444 405.838 367.042 405.425 366.64 405.023C361.881 400.217 359.26 393.793 359.26 387.109L358.15 305.66C358.043 300.192 356.579 294.736 353.45 290.379C353.45 290.331 353.402 290.331 353.402 290.272C347.734 282.478 338.582 277.377 328.213 277.377C311.716 277.377 298.218 290.272 297.202 306.416C297.096 307.077 297.096 307.786 297.096 308.435V355.235C297.096 362.522 293.955 369.454 288.44 374.355C285.499 376.929 282.11 378.759 278.52 379.763C276.241 380.365 273.867 380.72 271.482 380.72C270.372 380.72 269.309 380.673 268.199 380.519C267.242 380.413 266.321 380.212 265.412 379.964C254.181 377.177 245.986 366.501 245.986 354.113V280.353L238.806 279.998C180.173 277.27 124.623 264.268 82.2275 243.472C78.236 241.5 73.8784 239.268 69.1783 236.646C64.2657 233.718 60.3214 231.238 56.7314 228.911C42.6194 219.452 31.2353 209.225 23.087 198.502C20.5126 195.113 18.3869 191.877 16.462 188.429C14.4426 184.993 12.872 181.603 11.5494 178.155L11.0888 176.891C8.65613 169.959 7.45159 162.874 7.5933 155.894C8.30185 114.609 53.6846 78.2366 121.328 57.7002C119.604 63.5694 120.726 70.2417 125.272 75.4023L170.194 126.548C180.208 137.933 195.95 142.491 210.511 138.24L330.469 100.391C343.672 96.541 348.383 80.256 339.267 69.9346L316.853 44.3911C425.273 59.1173 505.41 108.09 504.395 164.551V164.562Z"
            fill="url(#paint3_linear_74_9)"
          />
          <path
            d="M299.198 54.37V71.7178C298.749 76.6777 295.407 81.2834 290.046 82.854L214.408 105.115C205.905 107.594 196.753 104.914 190.884 98.2887L158.456 61.3611C155.575 58.0781 155.575 50.0242 155.575 50.0242V49.0676L155.327 38.7463L155.279 36.7741V36.1719C154.972 30.5506 158.468 24.9885 164.49 23.1698L240.105 0.944748C248.548 -1.58244 257.665 1.05103 263.534 7.62881C263.534 7.62881 263.57 7.66423 263.641 7.73509L293.589 41.8285L296.069 44.6627C298.596 47.5442 299.564 51.0398 299.21 54.3818L299.198 54.37Z"
            fill="url(#paint4_linear_74_9)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_74_9"
          x1="-144.474"
          y1="557.115"
          x2="532.621"
          y2="244.279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E92" />
          <stop
            offset="1"
            stopColor="#FF31C5"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_74_9"
          x1="-196.222"
          y1="445.128"
          x2="480.884"
          y2="132.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E92" />
          <stop
            offset="1"
            stopColor="#FF31C5"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_74_9"
          x1="-138.073"
          y1="570.967"
          x2="539.021"
          y2="258.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E92" />
          <stop
            offset="1"
            stopColor="#FF31C5"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_74_9"
          x1="-197.237"
          y1="442.848"
          x2="479.822"
          y2="130.036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E92" />
          <stop
            offset="1"
            stopColor="#FF31C5"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_74_9"
          x1="155.244"
          y1="53.0119"
          x2="299.269"
          y2="53.0119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E92" />
          <stop
            offset="1"
            stopColor="#FF31C5"
          />
        </linearGradient>
        <clipPath id="clip0_74_9">
          <rect
            width="512"
            height="483"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_74_9">
          <rect
            width="512"
            height="483"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
