import { cn } from '@hapstack/common'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

import { HapstackIcon } from './icons/HapstackIcon'

type Props = {
  src?: string | null
  className?: string
  fallback?: string | null
}

export const Avatar = ({ fallback, src, className }: Props) => {
  const firstLetter = fallback?.charAt(0) || ''

  return (
    <AvatarPrimitive.Root
      className={cn(
        'flex aspect-square shrink-0 select-none items-center justify-center overflow-hidden',
        className
      )}
    >
      <AvatarPrimitive.Image
        src={src || undefined}
        alt={`${fallback} avatar`}
        className="h-full w-full object-contain"
      />
      <AvatarPrimitive.Fallback
        delayMs={200}
        className="flex h-full w-full items-center justify-center rounded bg-accent font-black uppercase text-white"
      >
        {firstLetter || (
          <div className="opacity-50">
            <HapstackIcon />
          </div>
        )}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  )
}
